import React, { useState } from "react"
import axios from "axios"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BG from "../../assets/2015/11/MAZD0294-Mazda-Foundation-Website-V4_03.jpg"

// Contact us - Google form
// mazdaaustraliafoundation@gmail.com
const FORM = "https://docs.google.com/forms/u/2/d/e/1FAIpQLSdxe8S4WtWNUrw0h5kKMo0TLMlMNugHLRoXFgNKnxof7GRuaw/formResponse"
const NAME = "entry.300880653"
const EMAIL = "emailAddress"
const SUBJECT = "entry.50107315"
const MESSAGE = "entry.1578082804"

const About = () => {
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")

  return (
    <Layout page="about">
      <SEO title="About" />
      <div
        id="about"
        style={{
          background: `url(${BG}) repeat`
        }}>
        <div id="title" className="green-bg vertical-padding-smaller center">
          <div className="container">
            <div className="horizontal-padding">
              <h1>ABOUT</h1>
            </div>
          </div>
        </div>
        <div className="vertical-padding-small">
          <div className="container">
            <div id="about" className="horizontal-padding">
              <p>
                In 1960, Mazda commenced operations in Australia, with the stated
                philosophy of “the enrichment of life and the achievement of
                excellence”. Throughout the ensuing years, Mazda has developed a
                marque of truly outstanding motor vehicles characterised by
                technological innovation and superb quality.
                <br />
                <br />
                Mazda has also participated in the community beyond the
                traditional role of an automotive organisation: since its
                inception, Mazda has fostered a spirit of community involvement.
                It is from this that the concept of the Mazda Foundation emerged.
                <br />
                <br />
                The Mazda Foundation was established in 1990 as a public
                charitable trust to provide assistance to a broad cross-section of
                worthy charitable entities and causes throughout Australia which
                qualify for income tax deductibility for gifts under what is now
                item 1 of the table in Section 30-15 of the Income Tax Assessment
                Act 1997 (“the Tax Act”), formerly Section 78(1) of the Tax Act
                1936. In addition, eligible recipients of grants by Mazda
                Foundation must now be endorsed as deductible gift recipients
                under the provisions of subdivision 30-BA of the Tax Act.
                <br />
                <br />
                The trustee of the Mazda Foundation is Mazda Foundation Limited
                (ABN 12 465 501 576), controlled independently of the trust’s
                Founder, Mazda Australia Pty Limited.
                <br />
                <br />
                Communications should be directed to:
              </p>
            </div>
            <div className="columns">
              <div className="one_half column">
                <p>
                  <b>The Secretary</b><br />Mazda Foundation<br />Private Bag 40<br />Mount Waverley BC Vic 3149
                  <br/><br/>Telephone: (03) 8540 1800<br />Facsimile: (03) 8540 1920<br /><br />
                  <a href="mailto:info@mazdafoundation.org.au">info@mazdafoundation.org.au</a>
                </p>
              </div>
              <div className="one_half column">
                <form
                  method="POST"
                  onSubmit={e => {
                    e.preventDefault()
                    if (!loading) {
                      const formData = new FormData()
                      formData.append(NAME, name)
                      formData.append(EMAIL, email)
                      formData.append(SUBJECT, subject)
                      formData.append(MESSAGE, message)
                      
                      setLoading(true)
                      axios
                        .post(FORM, formData)
                        .then(response => {
                          setLoading(false)
                          setResult("success")
                        })
                        .catch(error => {
                          setLoading(false)
                          setResult("success")
                        })
                    }
                  }}>
                  <p>
                    <b>Your Name (required)</b><br />
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={e => setName(e.target.value)}
                      size="40"
                      required
                      aria-required="true"
                      aria-invalid="false" />
                  </p>
                  <p>
                    <b>Your Email (required)</b><br />
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      size="40"
                      required
                      aria-required="true"
                      aria-invalid="false" />
                  </p>
                  <p>
                    <b>Subject</b><br />
                    <input
                      type="text"
                      name="subject"
                      value={subject}
                      onChange={e => setSubject(e.target.value)}
                      size="40" />
                  </p>
                  <p>
                    <b>Your Message</b><br />
                    <textarea
                      name="message"
                      cols="40"
                      rows="10"
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      aria-invalid="false" />
                  </p>
                  <input
                    type="submit"
                    value="Send"
                    className="button" />
                  <br /><br />
                  {
                    result && (
                      result === "failure" ?
                      <div className="form-failure">
                        Something went wrong. Please try again later.
                      </div>
                      :
                      <div className="form-success">
                        Your message was sent successfully. Thanks.
                      </div>
                    )
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
